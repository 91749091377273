import { graphql, navigate } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import Link from "src/utils/Link"
import * as styles from "./styles.module.scss"
import BlogThumbnail from "src/components/BlogThumbnail"
import { usePages } from "src/context/pages"
import ArrowLink from "src/components/ArrowLink"
import Logo from "src/images/structured-data-logo.png"
import BlogSubFooter from "src/components/BlogSubFooter"
import GetStarted from "src/components/Banners/GetStarted"
import SubNavBlog from "src/components/SubNavBlog"
import BlogArticles from "src/components/BlogArticles"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
    allWordpressPost: { nodes },
    allWordpressCategory: { nodes: categories },
  },
  pageContext: { category, numPages, currentPage },
}) => {
  const { currentPath } = usePages()

  return (
    <Layout
      title={`${
        category === "/"
          ? "Ascent Blog"
          : category.charAt(0).toUpperCase() + category.slice(1)
      }${texts.metaTitle}`}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <SubNavBlog categories={categories} />
      <div className={"content-wrapper"}>
        {category === "/" ? (
          <h1 className={styles.pageTitle}>All Posts</h1>
        ) : (
          <h1 className={styles.pageTitle}>{category}</h1>
        )}
        <div className={styles.postList}>
          {nodes.map((node) => (
            <div
              key={node.slug}
              className={styles.post}
              onClick={() => navigate(`/blog/${node.slug}`)}
            >
              <div className={styles.imgContent}>
                <BlogThumbnail
                  url={
                    (node.acf && node.acf.thumbnail) ||
                    node.jetpack_featured_media_url
                  }
                />
              </div>
              <div className={styles.infoContent}>
                <Link
                  to={`/blog/${node.slug}`}
                  className={styles.title}
                  dangerouslySetInnerHTML={{ __html: node.title }}
                />
                <p className={styles.info}>
                  <span className={styles.author}>{node.author.name}</span>
                  {node.categories.map((categ) => (
                    <Link
                      to={`/blog/category/${categ.slug}`}
                      className={styles.categorie}
                      onClick={(evt) => evt.stopPropagation()}
                      dangerouslySetInnerHTML={{ __html: categ.name }}
                    />
                  ))}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          {currentPage != 1 &&
            (currentPage == 2 ? (
              <ArrowLink
                text="Prev"
                reverse={true}
                className={styles.link}
                to={
                  category === "/" ? `/blog/all` : `/blog/category/${category}`
                }
              />
            ) : (
              <ArrowLink
                text="Prev"
                reverse={true}
                className={styles.link}
                to={
                  category === "/"
                    ? `/blog/all/${currentPage - 1}`
                    : `/blog/category/${category}/${currentPage - 1}`
                }
              />
            ))}
          {currentPage != numPages && (
            <ArrowLink
              text="Next"
              to={
                category === "/"
                  ? `/blog/all/${currentPage + 1}`
                  : `/blog/category/${category}/${currentPage + 1}`
              }
            />
          )}
        </div>
      </div>
      <BlogArticles nodes={nodes} />
      <div className={`content-wrapper ${styles.subfooter}`}>
        <BlogSubFooter />
        <GetStarted className={styles.banner} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query(
    $ymlPath: String!
    $skip: Int!
    $limit: Int!
    $categoryRegex: String!
  ) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        header
        allPosts
        metaTitle
        metaDescription
      }
    }
    allWordpressPost(
      limit: $limit
      skip: $skip
      filter: {
        categories: {
          elemMatch: {
            slug: { regex: $categoryRegex }
            name: { ne: "Uncategorized" }
          }
        }
      }
    ) {
      nodes {
        slug
        title
        author {
          name
        }
        categories {
          name
          slug
        }
        date(formatString: "MMMM D, YYYY")
        jetpack_featured_media_url
        acf {
          thumbnail
        }
      }
    }
    allWordpressCategory(filter: { name: { ne: "Uncategorized" } }) {
      nodes {
        name
        slug
      }
    }
  }
`

export default Template
